import React, { Component } from "react"
import "./ClientsContainer.css"
class ClientsContainer extends Component {
  render() {
    return (
      <>
        <div className="container-fluid" id="card1">
          <div className="container ">
            <div className="container pt-5">
              <div className="text-center">
                <span className="text-muted pulso-objetivo">
                  <em>{this.props.data.someText}</em>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid pt-5">
          <div className="container">
            <h2 className="display-4">{this.props.data.title}</h2>
            <div className="row">
              {this.props.data.clientLogo.map(data => {
                return (
                  <div className="col-md-6 ">
                    <img className="customer-logo" src={data.file.url} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  }
}

export default ClientsContainer
