import React, { Component } from "react"
import "./ContactUs.css"
import { sendEmail } from "../../libs/API"
class ContactUsContainer extends Component {
  constructor() {
    super()
    this.state = {
      email: "",
      text: "",
      attachment: null,
      isLoading: false,
    }
  }
  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ isLoading: true })
      try {
        const a = await sendEmail(this.state.email, this.state.text)
        this.setState({ isLoading: false })
        alert("tu email ha sido enviado!")
      } catch (e) {
        this.setState({ isLoading: false })
        alert("ups! algo salio mal, intentalo de nuevo")
      }
    
  }

  handleChange = e => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  render() {
    const data = this.props.data
    return (
      <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
        <span className="masthead mb-auto">
          <div className="inner"></div>
        </span>
        <h2 className="display-4">Contacto</h2>
        <div className="row mx-0">
          <div className=" text-center col-md-5 ml-xs-0 mx-auto">
            <div className=" pt-4">
              <address>
                <h5>Correo:</h5>

                <a className="address" href="mailto:info@pulso-rh.com">
                  {data.info.correo}
                </a>
                <br />
                <br />
                <br />
                <h5>
                  Telefono:
                  <br />
                </h5>
                <a className="address" title="Phone" href="tel:+5218445977513">
                  +{data.info.telefono}
                </a>

                <br />
                <br />
                <h5>
                  Ubicación:
                  <br />
                </h5>
                {data.info.ubicacion}
              </address>
            </div>
          </div>

          <div className=" text-center col-md-5  ml-xs-0 mx-auto">
            <div className="text-center pt-4">
              <h5 className="">Mandanos un Correo</h5>
            </div>
            <div className=" pt-1">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label for="email">Correo</label>
                  <input
                    type="email"
                    className="form-control shadow-sm"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Correo"
                    id="email"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />
                  <small className="form-text text-muted">
                    Tu correo es confidencial e importante para nosotros
                  </small>
                </div>

                <div className="form-group">
                  <label for="text">Mensaje</label>
                  <textarea
                    className="form-control shadow-sm"
                    name="text"
                    rows="3"
                    placeholder="Como podemos ayudarte?"
                    id="text"
                    value={this.state.text}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-outline-success pulso-rh_sendButton"
                >
                  {this.state.isLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      id="spinner"
                    ></span>
                  )}
                  &nbsp;Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
        <footer className="mastfoot mt-auto">
          <div className="social">
            {data.socialMedia.map(data => {
              return (
                <a href={data.link.url}>
                  <img src={data.logo.file.url} className="rounded" />
                </a>
              )
            })}
          </div>
          <div className="inner">
            <p>Pulso RH S.A de C.V &reg;</p>
          </div>
        </footer>
      </div>
    )
  }
}

export default ContactUsContainer
