export default function configuration() {
  if (process.env.REACT_APP_STAGE === "production") {
    return {
      s3: {
        REGION: "us-east-1",
        BUCKET: "",
      },
      apiGateway: {
        REGION: "us-east-1",
        API_URL: " https://wfpvzyepd7.execute-api.us-east-1.amazonaws.com/prod/",
      },
    }
  } else {
    return {
      s3: {
        REGION: "us-east-1",
        BUCKET: "",
      },
      apiGateway: {
        REGION: "us-east-1",
        API_URL: " https://wfpvzyepd7.execute-api.us-east-1.amazonaws.com/prod/",
      },
    }
  }
}
