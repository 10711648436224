import React, { Component } from "react"
import "./AboutUs.css"
class AboutUs extends Component {
  render() {
    const data = this.props.data

    return (
      <>
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <span className="masthead">
            <div className="inner"></div>
          </span>

          <div className="inner cover masthead">
            <div className="inner cover masthead"></div>
            <div className="container">
              <h1 className="cover-heading">Nosotros</h1>
              <p className="lead main-header">
                Somos una empresa mexicana creada por profesionales y
                especialistas en Recursos Humanos, con el propósito de
                posicionarnos como el socio estratégico que contribuya al
                crecimiento y desarrollo de nuestros clientes.
              </p>
              <p className="lead">
                <a href="#objetivo">
                  <img
                    className="img-scroll"
                    src={data.media.scroll.file.url}
                    width="100"
                  />
                </a>
              </p>
            </div>
          </div>

          <footer className="mastfoot ">
            <div className="inner">
              <p>Pulso RH S.A de C.V &reg;</p>
            </div>
          </footer>
        </div>
        <div className="container-fluid body-light py-4" id="objetivo">
          <div className="container pt-5">
            <div className="row">
              <div className="text-center">
                <span className="text-muted">
                  <em>
                    Nuestro principal objetivo es atraer, desarrollar y retener
                    el talento humano de nuestros clientes.
                  </em>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid body-light" id="mision">
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-6 order-md-3 text-left">
                <h2 className="display-4">
                  Mision <br />
                  <span className="text-muted">
                    <em className="mision">{data.mision}</em>
                  </span>
                </h2>
                <p className="lead"></p>
              </div>
              <div className="col-md-6 order-md-2 mt-4">
                <img
                  id="mision"
                  className=" img-fluid mx-auto"
                  src={data.media.manyImages[0].file.url}
                  width="600"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid body-light py-4" id="vision">
          <div className="container pt-5">
            <div className="row">
              <div className="col-md-6 order-md-2 text-right">
                <h2 className=" display-4">
                  Vision <br />
                  <span className="text-muted">
                    <em className="vision">{data.vision}</em>
                  </span>
                </h2>
              </div>
              <div className="col-md-6 order-md-3 mt-4">
                <img
                  id="vision"
                  className=" img-fluid mx-auto rounded"
                  src={data.media.manyImages[1].file.url}
                />
              </div>
            </div>
          </div>
        </div>
        {
          //VALORES
        }
        <div className="container-fluid">
          <div className="container">
            <h2 className="display-4">Valores</h2>
            <div className="row">
              {data.valores.map(data => {
                return (
                  <div className="valores col-md-3 mt-2">
                    <h3>{data.valor}</h3>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {
          //LEADERSHIP
        }
        <div className="container-fluid bg-light pt-4" id="equipo">
          <div className="container text-center pt-4">
            <h3 className="display-4 hr-blue">
              <em>Staff</em>
            </h3>
          </div>
          <div className="row body-light">
            <div className="container px-5 pb-1">
              <div className="row featurette mt-5 mb-5">
                {data.reviews.map(data => {
                  return (
                    <div className="col-sm text-center">
                      <h2 className="mt-4">{data.name}</h2>

                      <blockquote className="blockquote text-center">
                        <p>
                          <em>{data.review.review}</em>
                        </p>
                        <footer className="blockquote-footer">
                          {data.position}
                        </footer>
                      </blockquote>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        <hr />
      </>
    )
  }
}

export default AboutUs
