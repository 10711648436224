import React, { Component } from "react"
import "./HomeCover.css"
class HomeCover extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {}

  render() {
    let { data } = this.props
    return (
      <div id="main" className="container-fluid p-0">
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <span className="masthead">
            <div className="inner"></div>
          </span>

          <div className="inner cover masthead">
            <div className="inner cover masthead"></div>

            <div className="container">
              <img src={data.image.file.url} className="main-logo" />
              <a href="#carousel">
                <img
                  src={data.scroll.file.url}
                  className="img-scroll"
                  width="100"
                />
              </a>
            </div>
          </div>
          <footer className="mastfoot">
            <div className="inner">
              <p>Pulso RH S.A de C.V &reg;</p>
            </div>
          </footer>
          <h1 className="aaa-h1">{data.title}</h1>
        </div>
      </div>
    )
  }
}

export default HomeCover
