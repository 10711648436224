import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout/Layout.js"
//import AccountLayout from '../components/AccountLayout/AccountLayout';
import DynamicComponent from "../components/DynamicComponent"
import "../styles/global.css"
import "bootstrap/dist/css/bootstrap.css"
import Amplify from "aws-amplify"
import configuration from "../config"
const config = configuration()

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "API",
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
})
// const propTypes = {
//   data: PropTypes.object.isRequired,
// };

// const LayoutMap = {
//   standard: Layout,
//   standard_no_nav: Layout,
//  // account: AccountLayout,
// };

// const LayoutOptions = {
//   standard: {},
//   account: {},
//   standard_no_nav: { hideNavigation: true },
// }

/**
 * Checking for component internal props is important as well as checking for contents in general.
 * Contentful will still populate `contents` with an object the the `__typename` property,
 * even if you haven't added the component to the query.
 */
const Contents = ({ contents, location, specialContent }) => {
  if (contents) {
    return contents
      .filter(component => !!component.id)
      .map(component => (
        <DynamicComponent
          key={component.id}
          component={component}
          specialContent={specialContent}
        />
      ))
  }
  return null
}

class StandardTemplate extends React.Component {
  constructor() {
    super()

    this.state = {
      specialContent: null,
    }
  }

  componentDidMount() {}

  render() {
    const { data } = this.props

    const page = data.contentfulPage
    console.log(page)
    // const LayoutComponent = LayoutMap[type];
    //const layoutOptions = LayoutOptions[type];

    const meta = page.metaTags
      ? page.metaTags.map(item => ({
          name: item.name,
          content: item.description,
        }))
      : []

    return (
      <Layout>
        <Helmet
          title={page.title}
          meta={[
            {
              name: "viewport",
              content:
                "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
            },
            ...meta,
          ]}
          script={[]}
        />

        <Contents contents={page.content} />
      </Layout>
    )
  }
}

//StandardTemplate.propTypes = propTypes;

export default StandardTemplate

//
// Common errors
//

// contents are Content Models from Contentful
// If you create a new content model and you add it into the graphql code. You will get an error. The solution is to add first the content in some page.
// If yue create de content model but you dont put it into the grapqhwl code then will do anything.

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      Path
      content {
        ... on ContentfulHomeCover {
          id
          __typename
          title
          image {
            id
            file {
              url
            }
          }
          scroll {
            id
            file {
              url
            }
          }
        }
        ... on ContentfulCarousel {
          id
          __typename
          tittle
          manyImages {
            title
            file {
              url
            }
            description
          }
        }

        ... on ContentfulClientsContainer {
          id
          __typename
          title
          someText
          clientLogo {
            file {
              url
            }
          }
        }

        ... on ContentfulContactUsContainer {
          id
          __typename
          title
          info {
            telefono
            ubicacion
            correo
          }
          socialMedia {
            title
            logo {
              file {
                url
              }
            }
            link {
              url
            }
          }
        }

        ... on ContentfulServicesContainer {
          id
          __typename
          services {
            description {
              description
            }
            title
          }
          title
        }

        ... on ContentfulAboutUs {
          id
          __typename
          vision
          objetivo
          nosotros
          mision
          reviews {
            position
            name
            review {
              review
            }
          }
          valores {
            valor
          }
          media {
            manyImages {
              file {
                url
              }
            }
            scroll {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulVacantesContainer {
          id
          __typename
          title
          vacante {
            competencias
            requisitos
            ubicacion
            title
          }
        }
      }
    }
  }
`
