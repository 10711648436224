import React, { Component } from "react"
import "./ServicesContainer.css"
class ServicesContainer extends Component {
  render() {
    const data = this.props.data
    return (
      <>
        <div class="container-fluid p-0">
          <div class=" d-flex mt-5 p-3 mx-auto flex-column">
            <h1 class="display-3">Servicios</h1>
            <div class="row mx-5 py-3" id="services">
              {data.services.map(data => {
                return (
                  <div class="container col-md-6 pt-3 ">
                    <div class="box px-0 shadow">
                      <div class="content">
                        <h3>{data.title}</h3>

                        <p class="lead">{data.description.description}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div class="bg-white py-4">
            <div class="container">
              <h4 class="h1 soon">
                <em>Proximamente</em>
              </h4>
              <div>
                <div class="row">
                  <div class="container col-md-3 pt-3 ">
                    <div class="soon-box p-3  shadow">
                      <h5>Administración de Nóminas</h5>
                    </div>
                  </div>
                  <div class="container col-md-3 pt-3 ">
                    <div class="soon-box p-3  shadow">
                      <h5>Diagnósticos Laborales</h5>
                    </div>
                  </div>
                  <div class="container col-md-3 pt-3 ">
                    <div class="soon-box p-3  shadow">
                      <h5>Investigaciones Laborales</h5>
                    </div>
                  </div>
                  <div class="container col-md-3 pt-3 ">
                    <div class="soon-box p-3  shadow">
                      <h5>Outsourcing</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </>
    )
  }
}

export default ServicesContainer
