import React, { Component } from "react"
//import Collapse from "react-bootstrap/Collapse";
class VacantesContainer extends Component {
  render() {
    const data = this.props.data
    
    return (
      <div class="container-fluid p-0">
        <div class="d-flex mt-5  p-3 mx-auto flex-column">
          <div class="container ">
            <h1 class="display-3">{data.title}</h1>

            {data.vacante.map((a, i) => {
              return (
                <div class="box">
                  <div class="text-center p-2">
                    <h2 class="hr-blue mx-auto">
                      <em>
                        {a.title}
                        <span class="text-muted"> {a.ubicacion}</span>
                      </em>
                    </h2>

                    {/*<Collapse in={true} >*/}
                    <div class="lead text-left" id={i}>
                      <h3 class="text-center">Requisitos</h3>
                      <ul>
                        {a.requisitos.map(a => (
                          <li>{a}</li>
                        ))}
                      </ul>
                    {
                      a.competencias &&
                      <>Competencias:
                          <ul>
                        {a.competencias.map(a => (
                          <li>{a}</li>
                          ))}
                      </ul></>
              } 
                    </div>
                    {/*</Collapse>*/}
                    {/* {
                 <a href="#" class="lead" onClick={()=>opensad()} aria-controls={i} aria-expanded={open}>
                      Mostrar mas
                 </a>
                } */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default VacantesContainer
