import Carousel from "react-bootstrap/Carousel"
import React, { Component } from "react"

class CarouselComponent extends Component {
  render() {
    const slides = this.props.data.manyImages
    return (
      <Carousel id="carousel">
        {slides.map(data => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={data.file.url}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>{data.title}</h3>
                <p>{data.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    )
  }
}

export default CarouselComponent
