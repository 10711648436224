import React from "react"

// Simple Components
import HomeCover from "./HomeCover/HomeCover"
import CarouselComponent from "./Carousel/Carousel"
import AboutUs from "./AboutUs/AboutUs"
//Conatiners
import ClientsContainer from "./ClientsContainer/ClientsContainer"
import ContactUsContainer from "./ContactUsContainer/ContactUsContainer"
import ServicesContainer from "./ServicesContainer/ServicesContainer"
import VacantesContainer from "./VacantesContainer/VacantesContainer"
/**
 * We use this map to dynamically load components based on the content model for a
 * reference provided by Contentful. Keys are the Content Models that come from Contentful,
 * which is accessed via component.__typename.
 */
const ComponentMap = {
  // Simple Components
  ContentfulHomeCover: HomeCover,
  ContentfulCarousel: CarouselComponent,
  ContentfulAboutUs: AboutUs,
  // Containers
  ContentfulContactUsContainer: ContactUsContainer,
  ContentfulClientsContainer: ClientsContainer,
  ContentfulServicesContainer: ServicesContainer,
  ContentfulVacantesContainer: VacantesContainer,
}

const DynamicComponent = ({ component, location, specialContent }) => {
  const Component = ComponentMap[component.__typename]

  return (
    <Component
      data={component}
      location={location}
      specialContent={specialContent}
    />
  )
}

export default DynamicComponent
